import React, {useContext, useEffect, useState,} from 'react'
import {Fade} from 'react-reveal'
import BlogCard from '../../components/blogCard/BlogCard'
import StyleContext from '../../contexts/StyleContext'
import {blogSection} from '../../portfolio'
import './Blog.scss'

export default function Blogs() {
  const [articles, setArticles] = useState([])
  const { isDark } = useContext(StyleContext)

  useEffect(() => {
    fetch(
      'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sec-ahmedosama',
    )
      .then((res) => res.json())
      .then((res) => {
          if (res.items) {
              setArticles((prevState) =>
                  prevState.concat(
                      res.items.map((article) => ({
                          title: article.title,
                          url: article.link,
                          description: article.description.replace(
                              /<\/?[^>]+>/gi,
                              '',
                          ),
                      })),
                  ),
              )
          }
      })
    fetch(
      'https://dev.to/api/articles?username=ahmedosama_st',
    )
      .then((res) => res.json())
      .then((articles) => {
          if (articles) {
              setArticles((prevState) =>
                  prevState.concat(
                      articles.map((article) => ({
                          title: article.title,
                          url: article.url,
                          description: article.description,
                      })),
                  ),
              )
          }
      })
  }, [])

  if (!blogSection.display) {
    return null
  }

  return (
    <Fade bottom duration={1000} distance='20px'>
      <div className='main' id='blogs'>
        <div className='blog-header'>
          <h1 className='blog-header-text'>
            {blogSection.title}
          </h1>
          <p
            className={
              isDark
                ? 'dark-mode blog-subtitle'
                : 'subTitle blog-subtitle'
            }
          >
            {blogSection.subtitle}
          </p>
        </div>
        <div className='blog-main-div'>
          <div className='blog-text-div'>
            {articles.map((blog, i) => {
              return (
                <BlogCard
                  key={i}
                  isDark={isDark}
                  blog={{
                    url: blog.url,
                    image: blog.image,
                    title: blog.title,
                    description: blog.description,
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Fade>
  )
}
