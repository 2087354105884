/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

const illustration = {
    animated: true, // Set to false to use static SVG
};

const greeting = {
    username: 'Ahmed Osama',
    title: 'Welcome on board',
    subTitle: 'A passionate full stack developer with a tendency teaching other developers advanced concepts using React, Nestjs, RxJS, Functional programming, data structures, laravel and more other technologies',
    displayGreeting: true, // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
    github: 'https://github.com/ahmedosama-st',
    linkedin: 'https://www.linkedin.com/in/ahmed-osama-st/',
    gmail: 'hello@ahmedosama-st.com',
    dev: 'https://dev.to/ahmedosama_st',
    display: true, // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
    title: 'What I do',
    subTitle:
        'EXPLORING ADVANCED AND NEW TECHNIQUES IN REACTIVE PROGRAMMING, FUNCTIONAL PROGRAMMING, CONCURRENCY, AND ARCHITECTURES, AND TEACHING THEM TO OTHERS',
    skills: [
        '⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications',
        '⚡ Designing scalable backend applications',
        '⚡ Consulting others about their code infrastrcuture for a better performance',
        '⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks',
        '⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean',
    ],

    /* Make Sure to include correct Font Awesome Classname to view your icon
  https://fontawesome.com/icons?d=gallery */

    softwareSkills: [
        {
            skillName: 'JavaScript',
            fontAwesomeClassname: 'fab fa-js',
        },
        {
            skillName: 'reactjs',
            fontAwesomeClassname: 'fab fa-react',
        },
        {
            skillName: 'vuejs',
            fontAwesomeClassname: 'fab fa-vuejs',
        },
        {
            skillName: 'nodejs',
            fontAwesomeClassname: 'fab fa-node',
        },
        {
            skillName: 'php',
            fontAwesomeClassname: 'fab fa-php',
        },
        {
            skillName: 'laravel',
            fontAwesomeClassname: 'fab fa-laravel',
        },
        {
            skillName: 'SQL',
            fontAwesomeClassname: 'fas fa-database',
        },
        {
            skillName: 'azure',
            fontAwesomeClassname: 'fab fa-microsoft',
        },
        {
            skillName: 'AWS',
            fontAwesomeClassname: 'fab fa-aws',
        },
        {
            skillName: 'docker',
            fontAwesomeClassname: 'fab fa-docker',
        },
    ],
    display: true, // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
    display: true, // Set false to hide this section, defaults to true
    schools: [
        {
            schoolName: 'Ain Shams University',
            logo: require('./assets/images/ain-shams-logo.png'),
            subHeader: 'Civil Engineering',
            duration: 'Sep. 2019 - Jun. 2022',
        },
        {
            schoolName: 'Akhbar el yom',
            logo: require('./assets/images/ahkbar-academy-logo.png'),
            subHeader: 'Computer Science',
            duration: 'Sep. 2023 - Present',
        },
    ],
};

// Your top 3 proficient stacks/tech experience

const techStack = {
    viewSkillBars: true, //Set it to true to show Proficiency Section
    experience: [
        {
            Stack: 'UI/UX', //Insert stack or technology you have experience in
            progressPercentage: '40%', //Insert relative proficiency in percentage
        },
        {
            Stack: 'Frontend', //Insert stack or technology you have experience in
            progressPercentage: '61%', //Insert relative proficiency in percentage
        },
        {
            Stack: 'Backend',
            progressPercentage: '91%',
        },
        {
            Stack: 'Programming',
            progressPercentage: '80%',
        },
    ],
    displayCodersrank: false, // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
    display: true, //Set it to true to show workExperiences Section
    experience: [
        {
            role: 'Senior Software Engineer',
            company: 'NFQ Egypt',
            companylogo: require('./assets/images/nfq-logo.png'),
            date: 'Jun. 2023 - Present',
            desc: 'Since 2002, NFQ have been providing both strategy and execution to entrepreneurs who seek to scale and outcompete. NFQ are a team of 800 professionals across Germany, Lithuania, Poland, Vietnam, Thailand, Singapore, and Egypt.',
            descBullets: []
        },
        {
            role: 'Senior Software Engineer',
            company: 'Otherboard',
            companylogo: require('./assets/images/otherboard-logo.png'),
            date: 'Jan. 2023 – Jun. 2023',
            desc: 'Otherboard provides their users with an analytics dashboard which connects your social platforms (Wordpress, YouTube, Facebook, Pinterest, Instagram, and Google) in a single view. Where you can track your accounts activity, insights, accounting, and other means of viewing your social reach.',
            descBullets: []
        },
        {
            role: 'Software Engineer',
            company: 'Andela',
            companylogo: require('./assets/images/andela-logo.png'),
            date: 'April. 2022 – Present',
            desc: 'Andela is a global job placement network for software developers. Andela focuses on sustainable careers, connecting technologists with long-term engagements, access to international roles, competitive compensation, and career coaching through the Andela Learning Community.',
            descBullets: []
        },
        {
            role: 'Mid-Senior Software Engineer',
            company: 'Spryker Systems',
            companylogo: require('./assets/images/spryker-logo.png'),
            date: 'Dec. 2021 – Jun. 2023',
            desc: "Spryker enables companies to create winning transactional business models in B2B, Enterprise Marketplaces, Unified Commerce and B2C. It is the most modern cloud native platform-as-a-service solution with over 100% year-over-year growth, headless, API based, enterprise-ready, and it's loved by Sales, Marketeers, and Developers worldwide",
            descBullets: [],
        },
        {
            role: 'Backend instructor/developer',
            company: 'Denizey',
            companylogo: require('./assets/images/denizey-logo.jpg'),
            date: 'June 2021 - Oct 2021',
            desc: 'Denizey is an online platform for teaching software development to various levels of developers in different tech stacks.',
            descBullets: [],
        },
        {
            role: 'Backend instructor/developer',
            company: 'SecTheater',
            companylogo: require('./assets/images/sectheater-logo.jpg'),
            date: 'Sep 2016 – Sep. 2020',
            desc: 'SecTheater is a platform where software engineers from different levels can find courses to enhance their technical skills, our goal is to become the most resourceful and best source for all developers in the Middle East and especially in Egypt.',
            descBullets: [],
        },
    ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
    showGithubProfile: 'true', // Set true or false to show Contact profile using Github, defaults to true
    display: false, // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
    title: 'Big Projects',
    subtitle: 'SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH',
    projects: [
        {
            image: require('./assets/images/saayaHealthLogo.webp'),
            projectName: 'Saayahealth',
            projectDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            footerLink: [
                {
                    name: 'Visit Website',
                    url: 'http://saayahealth.com/',
                },
                //  you can add extra buttons here.
            ],
        },
        {
            image: require('./assets/images/nextuLogo.webp'),
            projectName: 'Nextu',
            projectDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            footerLink: [
                {
                    name: 'Visit Website',
                    url: 'http://nextu.se/',
                },
            ],
        },
    ],
    display: false, // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
    title: 'Published courses',
    subtitle:
        "The courses I've made and the upcoming ones and the certificates, badges I've earned",

    achievementsCards: [
        {
            title: 'JavaScript Internals',
            subtitle:
                'A deep dive into the under the hoods of javascript like JSVM, JS Runtime, memory heap and more',
            image: require('./assets/images/js-internals.png'),
            footerLink: [
                {
                    name: 'View slides',
                    url: 'https://slides.com/sectheater/javascript-internals',
                },
                {
                    name: 'View course',
                    url: 'https://www.udemy.com/course/how-javascript-works-under-the-hood/',
                },
            ],
        },
        {
            title: 'JavaScript Foundation',
            subtitle:
                'Understanding the fundamental blocks of JS like types, type coercion, equality and other concepts',
            image: require('./assets/images/js-fundamentals.png'),
            footerLink: [
                {
                    name: 'View slides',
                    url: 'https://slides.com/sectheater/deep-javascript-foundation',
                },
                {
                    name: 'View course (coming soon)',
                    url: '',
                },
            ],
        },
        {
            title: 'Advanced prototypes in JS',
            subtitle:
                'A deep dive into the OOP system that JS implements using prototypes and functions',
            image: require('./assets/images/js-oop.png'),
            footerLink: [
                {
                    name: 'View slides',
                    url: 'https://slides.com/sectheater/beyond-oop-in-js',
                },
                {
                    name: 'View course (coming soon)',
                    url: '',
                },
            ],
        },
        ,
    ],
    display: true, // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
    title: 'Blogs',
    subtitle:
        'With Love for Developing cool stuff, I love to write and teach others what I have learnt.',

    blogs: [],
    display: true, // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
    title: 'TALKS',
    subtitle: 'I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅',

    talks: [
        {
            title: 'Build Actions For Google Assistant',
            subtitle: 'Codelab at GDG DevFest Karachi 2019',
            slides_url: 'https://bit.ly/saadpasta-slides',
            event_url: 'https://www.facebook.com/events/2339906106275053/',
        },
    ],
    display: false, // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
    title: 'Podcast 🎙️',
    subtitle: 'I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY',

    // Please Provide with Your Podcast embeded Link
    podcast: [
        'https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo',
    ],
    display: false, // Set false to hide this section, defaults to true
};

const contactInfo = {
    title: 'Contact Me ☎️',
    subtitle:
        'Discuss a project or just want to say hi? My Inbox is open for all.',
    email_address: 'hello@ahmedosama-st.com',
};

// Twitter Section

const twitterDetails = {
    userName: '', //Replace "twitter" with your twitter username without @
    display: false, // Set true to display this section, defaults to false
};

export {
    illustration,
    greeting,
    socialMediaLinks,
    skillsSection,
    educationInfo,
    techStack,
    workExperiences,
    openSource,
    bigProjects,
    achievementSection,
    blogSection,
    talkSection,
    podcastSection,
    contactInfo,
    twitterDetails,
};
